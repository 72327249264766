import { DateTime } from "luxon";

/**
 * Time units used in the monthly reset countdown
 */
export enum ResetTimeUnit {
    DAY = "day",
    HOUR = "hour",
    MINUTE = "minute",
}

/**
 * Return value of getTimeUntilMonthlyReset function
 */
export type nextResetTime = {
    unit: ResetTimeUnit;
    value: number;
    str: string;
};

/**
 * Calculates time remaining until the next monthly quest reset (first of next month at midnight Vancouver time)
 * Returns a human-readable string showing the most relevant time unit
 * @returns {nextResetTime} Time remaining (e.g., "12 days", "10 hours", "5 mins")
 */
export function getTimeUntilMonthlyReset(): nextResetTime {
    // Current time in user's local timezone
    const now = DateTime.local();

    // Get next month's first day at midnight Vancouver time
    const nextReset = DateTime.local()
        .setZone("America/Vancouver") // Convert to Vancouver time
        .plus({ months: 1 }) // Add one month
        .startOf("month") // Set to first of month
        .set({ hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0 }); // Set to midnight

    // Keep logs for easy debugging
    // console.log("Current time (local):", now.toLocaleString(DateTime.DATETIME_FULL));
    // console.log("Next reset (Vancouver):", nextReset.toLocaleString(DateTime.DATETIME_FULL));
    // console.log("User timezone:", now.zoneName);
    // console.log("Is DST in Vancouver at reset:", nextReset.isInDST);

    // Calculate the difference
    const diff = nextReset.diff(now, ["days", "hours", "minutes"]).toObject();

    // Round down all values
    const days = Math.floor(diff.days);
    const hours = Math.floor(diff.hours);
    const minutes = Math.floor(diff.minutes);

    // Return the most relevant time unit
    if (days > 0) {
        return {
            unit: ResetTimeUnit.DAY,
            value: days,
            str: `${days} ${days === 1 ? "day" : "days"}`,
        };
    }
    if (hours > 0) {
        return {
            unit: ResetTimeUnit.HOUR,
            value: hours,
            str: `${hours} ${hours === 1 ? "hr" : "hrs"}`,
        };
    }
    const finalMinutes = Math.max(minutes, 0);
    return {
        unit: ResetTimeUnit.MINUTE,
        value: finalMinutes,
        str: `${finalMinutes} ${finalMinutes === 1 ? "min" : "mins"}`,
    };
}