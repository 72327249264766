import {
    ButtonHTMLAttributes,
    ChangeEventHandler,
    FormEvent,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from "react";
import {MenuOrdersApi, PhoneNumberBody} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import FrameOneAutoPanel from "../../modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "../../modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "../../modals/autoPanelComponents/FrameAutoPanelBody";
import {addError} from "@/redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import {useDispatch} from "react-redux";
import FrameOnePhoneNumberInput from "../../inputs/FrameOnePhoneNumberInput";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {ReactComponent as AddPhone} from "../../../svgs/add-phone.svg";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import { useStreamerMode } from "@/hooks/useStreamerMode";

interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumberBody;
}

const defaultValues: FormValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: {
        countryCode: "US",
        nationalNumber: "",
    },
};

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function CheckoutDetailsContactInfoModal(props: Props): ReactElement {

    const { menuOrderId } = useContext(RestaurantContext);

    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(menuOrderId);
    const { hideInputText } = useStreamerMode();
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState<FormValues>(defaultValues);
    const isMissingContactInfo = !menuOrder?.phoneNumber || !menuOrder?.firstName || !menuOrder?.lastName;

    useEffect(() => {
        if (props.isOpen && menuOrder) {
            setFormValues({
                firstName: menuOrder.firstName || "",
                lastName: menuOrder.lastName || "",
                email: menuOrder.email || "",
                phoneNumber: {
                    countryCode: "US",
                    nationalNumber: menuOrder.phoneNumber?.nationalNumber || "",
                },
            });
        }
    }, [props.isOpen]);

    function inputOnChange(key: keyof Pick<FormValues, "firstName" | "lastName" | "email">): ChangeEventHandler<HTMLInputElement> {
        return (e) => {
            setFormValues({
                ...formValues,
                [key]: e.target.value,
            });
        };
    }

    function phoneNumberOnChange(phoneNumber: PhoneNumberBody): void {
        setFormValues({
            ...formValues,
            phoneNumber,
        });
    }

    async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        try {
            // update menu order
            await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrder.id,
                createMenuOrderBody: {
                    ...formValues,
                },
            });
        } catch (e) {
            dispatch(await addError(e));
            return;
        }
        await refetchMenuOrder();
        props.onClose();
    }

    return (
        <FrameOneAutoPanel
            toggle={props.onClose}
            isOpen={props.isOpen}
            size="sm"
            contentClassName="menu-order-contact-info-modal"
            disableOverlayDismiss={isMissingContactInfo}
            modalOnTablet={true}
        >
            <FrameAutoPanelHeader
                title=""
            >
                <div className="menu-order-contact-info-modal_header">
                    <AddPhone/>
                    <span className="menu-order-contact-info-modal_header_title">
						Add Contact Information
                    </span>
                </div>
            </FrameAutoPanelHeader>

            <FrameAutoPanelBody className="menu-order-contact-info-modal_body">
                <form onSubmit={onFormSubmit}>
                    <p>
                        Please provide the details of the person who will receive the order.
                        This step is required to proceed with your order.
                    </p>

                    <div className="menu-order-contact-info-modal_body_divider"/>

                    <div className="menu-order-contact-info-modal_body_name-container">
                        <div className="frame-one-phone-input_input">
                            <label>
                                First Name
                            </label>

                            <input
                                type="text"
                                className="menu-order-contact-info-modal_body_name-input"
                                placeholder="First Name"
                                value={formValues.firstName}
                                onChange={inputOnChange("firstName")}
                                required={true}
                            />
                        </div>

                        <div className="frame-one-phone-input_input">
                            <label>Last Name</label>
                            <input
                                {...hideInputText()}
                                className="menu-order-contact-info-modal_body_name-input"
                                placeholder="Last Name"
                                value={formValues.lastName}
                                onChange={inputOnChange("lastName")}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="menu-order-contact-info-modal_body_email-container">
                        <label>Email</label>
                        <input
                            {...hideInputText()}
                            className="menu-order-contact-info-modal_body_name-input"
                            placeholder="Email"
                            value={formValues.email}
                            onChange={inputOnChange("email")}
                            required={true}
                        />
                    </div>

                    <div className="menu-order-contact-info-modal_body_phone-container">
                        <FrameOnePhoneNumberInput
                            value={ formValues.phoneNumber}
                            onChange={phoneNumberOnChange}
                        />
                    </div>

                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        size="normal"
                        className="menu-order-contact-info-modal_body_submit-button"
                        showSpinner={true}
                        forwardProps={{type: "submit"}}
                    >
                        Save and continue
                    </FrameButton>
                </form>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default CheckoutDetailsContactInfoModal;
